<template>
  <lv-modal :opened="opened" size="xl" :closable="closable">
    <template #content>
      <lv-heading
        level="4"
        regular
        align="center"
        :class="$style.title"
      >
        {{ $awt('aw.profile_modal.title') }}
      </lv-heading>
      <aw-profile-data-form-version2
        ref="profileDataForm"
        enable-birth-date-field-edit
        :show-cancel-button="false"
        :form-data="user.personalInfo"
        :section-card-style="$style"
        :show-save-button="false"
        :newsletter-subscription-id="newsletterSubscriptionId"
        :edm-subscription-id="edmSubscriptionId"
        :optin-subscription-id="optinSubscriptionId"
        :subscriptions="subscriptions"
        :enable-birth-date-field="enableBirthDateField"
        @validation-change="setValidtionStatus"
      />
      <lv-button styling="primary-dark" :disabled="submitDisabled" @click="saveForm">
        {{ $awt('aw.global.save') }}
      </lv-button>
    </template>
  </lv-modal>
</template>
<script>
  import { mapState } from 'pinia';
  import { LvHeading, LvModal, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_PROFILE } from '~~/common/config/Modal.js';
  import AwProfileDataFormVersion2 from '~~/shop/components/Page/Profile/Forms/AwProfileDataFormVersion2.vue';

  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useConfigStore } from '~~/common/stores/config';
  import { useUserStore } from '~~/common/stores/user';

  export default {
    name: 'AwProfileModal',
    components: {
      AwProfileDataFormVersion2,
      LvHeading,
      LvModal,
      LvButton,
    },
    data () {
      return {
        newsletterSubscriptionId: null,
        edmSubscriptionId: null,
        optinSubscriptionId: null,
        subscriptions: [],
        newsLetterDataLoaded: false,
        submitDisabled: true,
      };
    },
    computed: {
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      ...mapState(useOrderStore, {
        order: state => state.order,
      }),
      ...mapState(useUserStore, {
        user: state => state.data,
        isEcomUser: state => state.isEcomUser,
      }),
      ...mapState(useConfigStore, {
        enableBirthDateField (state) {
          return state.isSalesForceLogin && !this.user.personalInfo?.isB2B && !this.isGuestCheckout;
        },
      }),
      opened () {
        return this.modal.type === M_PROFILE;
      },
      closable () {
        return this.modal.item.closable;
      },
    },
    watch: {
      isEcomUser () {
        this.getSubscriptionInfo();
      },
    },
    mounted () {
      if (this.isEcomUser) {
        this.getSubscriptionInfo();
      }
    },
    methods: {
      setValidtionStatus (event) {
        this.submitDisabled = event.$invalid;
      },
      saveForm () {
        this.$refs.profileDataForm.onSubmitForm();
        this.closeModal();
      },
      async getSubscriptionInfo () {
        await this.fetchSubscriptionInfo();
        this.newsletterSubscriptionId = this.subscriptions?.find(subscription => subscription.subscriptionType === 'newsletter')?.id || null;
        this.edmSubscriptionId = this.subscriptions?.find(subscription => subscription.subscriptionType === 'edm')?.id || null;
        this.optinSubscriptionId = this.subscriptions?.find(subscription => subscription.subscriptionType === 'profiling')?.id || null;
        this.newsLetterDataLoaded = true;
      },
      async fetchSubscriptionInfo () {
        if (this.isEcomUser) {
          this.subscriptions = await this.$api.$get('/me/subscriptions');
        }
      },
      async closeModal () {
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        modalStore.closeProfileModal();

        if (this.isEcomUser && this.order.mergeNeeded) {
          await orderStore.fetchCartMerge();
          modalStore.openCartMergeModal();
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.title {
  margin-bottom: 32px;
}

.formButtonsContainer {
  margin-top: 8px;

  .button {
    @include md(max) {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
